import moment from 'moment';
import React from 'react';

import { ComponentConnectorFactory } from 'store/component-connector';
import { select } from 'store/selectors/state.selectors';

export enum FORMATS {
  DateAndTime = 0,
  DateOnly = 1,
  TimeOnly = 2
}

export function formatDate(dateTime: string | Date): string {
  return moment(dateTime).format('l');
}

export function formatTime(is12Hour: boolean, dateTime: string | Date, hasSeconds = true): string {
  if (is12Hour == null) {
    if (hasSeconds) {
      return moment(dateTime).format('h:mm:ss A');
    }
    return moment(dateTime).format('h:mm A');
  }

  let format;
  if (hasSeconds) {
    format = is12Hour ? 'h:mm:ss A' : 'HH:mm:ss';
  } else {
    format = is12Hour ? 'h:mm A' : 'HH:mm';
  }

  return moment(dateTime).format(format);
}

export function formatDateAndTime(date: Date, is12Hour: boolean, format = FORMATS.DateAndTime, hasSeconds = true) {
  let outputArray = [];

  if (format !== FORMATS.TimeOnly) {
    outputArray = [...outputArray, formatDate(date)];
  }

  if (format === FORMATS.DateAndTime || format === FORMATS.TimeOnly) {
    outputArray = [...outputArray, formatTime(is12Hour, date, hasSeconds)];
  }
  return outputArray.join(' ');
}

interface OwnProps {
  date: Date;
  format?: FORMATS;
  hasSeconds?: boolean;
  fallback?: string;
}

interface ConnectStateProps {
  is12Hour: boolean;
}

type Props = OwnProps & ConnectStateProps;

export const DateTimeFormatterContainer: React.FunctionComponent<Props> = ({is12Hour, date, format = FORMATS.DateAndTime, hasSeconds = true, fallback}) => {
  if (!date) {
    return <span>{fallback}</span>;
  }

  return <span>{formatDateAndTime(date, is12Hour, format, hasSeconds)}</span>;
};

export const DateTimeFormatter = ComponentConnectorFactory<OwnProps, ConnectStateProps>()
  .mapStateToProps<ConnectStateProps>(select.auth.user.is12Hour)
  .connect(DateTimeFormatterContainer);

export const setDayEndTime = (date: string): string => {
  return moment(date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
};
